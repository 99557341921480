import PatientAntecedent from '@/modules/patient/models/healthEntity/PatientAntecedent';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, putToAPI, deleteFromAPI } from '@/services/api';

export default class PatientHabitus extends PatientAntecedent {
  constructor (options = {}) {
    super(options);

    this.type = 'habitus';
    this.code = null;
    this.codeTerminology = null;
    this.theriaqueLink = null;
    this.habitus = options.habitus || null;
    this.habitusParameter = options.habitusParameter || null;
    this.value = options.value || null;
  }

  /**
   * Retourne le type d'habitus suivi de ses détails
   * Exemple: "Exercice physique (2 Fois / Semaine)"
   * @returns {String}
   */
  getDetailedHabitusLabel () {
    let habitusDetails = null;
    if (this.value) {
      const units = this.habitus.parameters?.units;
      const currentUnit = units.filter(unit => unit.code === this.habitusParameter)[0];
      habitusDetails = ` (${this.value} ${this.habitus.parameters.target} / ${currentUnit.label})`;
    }
    return [this.habitus.label, habitusDetails].filter(item => !! item).join('');
  }

  /**
   * Permet de récupérer les catégories d'habitus depuis l'API
   * @returns {Array}
   */
  static async fetchHabitusCategories () {
    const { data } = await getFromAPI('/api/habitus_refs');
    return data['hydra:member'];
  }

  /**
   * Permet de récupérer les habitus et modes de vie depuis l'API
   * @param {String} patientIri
   * @returns {PatientHabitus[]}
   */
  static async fetchAll (patientIri) {
    const { data } = await getFromAPI(`${patientIri}/medical_antecedents?exists[habitus]=true`);
    return data['hydra:member'].map(habitus => new PatientHabitus(habitus));
  }

  /**
   * Insère un habitus vers l'API
   * @param {String} patientIri
   * @param {Object} habitus
   * @returns {PatientHabitus}
   */
  static async insert (patientIri, habitus) {
    const { data } = await putToAPI(patientIri, { data: { newMedicalAntecedentList: [habitus] } });
    NovaTools.notify.success('L\'habitus, mode de vie a été créé avec succès');
    return new PatientHabitus(data);
  }

  /**
   * Met à jour un habitus à partir de l'API
   * @param {Object} habitus
   * @returns {PatientHabitus}
   */
  static async update (habitus) {
    const { data } = await putToAPI(habitus['@id'], { data: habitus });
    NovaTools.notify.success('L\'habitus, mode de vie a été mis à jour avec succès');
    return new PatientHabitus(data);
  }

  /**
   * Supprime un habitus à partir de l'API
   * @param {String} habitusIri
   */
  static async delete (habitusIri) {
    await deleteFromAPI(habitusIri);
    NovaTools.notify.success('L\'habitus, mode de vie a été supprimé avec succès');
  }
}