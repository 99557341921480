<template>
  <div>
    <validation-provider v-if="!isLoading" ref="pathologyValidator" v-slot="{ errors }" tag="div">
      <div class="pathology-autocomplete-wrapper" :class="tagsAlignmentClass">
        <pathology-autocomplete-widget-v2
          v-if="multiple || !hasSelection"
          v-test="'pathology-autocomplete'"
          class="flex-grow-1"
          :favorites="synapseFavorites"
          @input="handlePathologySelection($event)"
          @add-favorite="onFavoriteItemAdded"
          @delete-favorite="onFavoriteItemDeleted"
        />
        <div
          v-if="multiple && hasSelection"
          v-test="'selected-pathologies-tags'"
          class="pathology-autocomplete-wrapper__tags"
        >
          <app-tag
            v-for="(pathology, index) in value"
            :key="index"
            color="secondary"
            small
            closable
            @click:close="handlePathologyUnselection(index)"
          >
            {{ pathology.getLabel() }}
          </app-tag>
        </div>
      </div>
      <app-panel
        v-if="!multiple && hasSelection"
        v-test="'selected-pathology-panel'"
        is-outlined
        is-flat
        dense
        :title="value.getLabel()"
        :actions="actions"
      />
      <div v-if="errors && errors.length > 0" class="pathology-autocomplete-wrapper__error-messages">
        <p v-for="(error, index) in errors" :key="index" class="mt-2">
          {{ error }}
        </p>
      </div>
    </validation-provider>
    <app-circular-loader
      v-else
      class="justify-center"
    />
  </div>
</template>

<script>
import { ValidationProvider, validate } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import fieldMixin from '@/mixins/fields';
import PatientCodifiedHealthEntity from '@/modules/patient/models/healthEntity/PatientCodifiedHealthEntity';

import PathologyAutocompleteWidgetV2 from '@/components/lap/synapse-widgets/PathologyAutocompleteWidgetV2.vue';
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';

export default {
  name: 'PathologyAutocompleteWrapper',
  components: {
    PathologyAutocompleteWidgetV2,
    AppPanel,
    AppCircularLoader,
    AppTag,
    ValidationProvider,
  },
  mixins: [fieldMixin],
  props: {
    value: {
      type: [PatientCodifiedHealthEntity, Array],
      default: null,
      validator: (value) => {
        if (!!value || value instanceof PatientCodifiedHealthEntity) {
          return true;
        }
        return value.every(item => item instanceof PatientCodifiedHealthEntity);
      },
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    tagsAlignment: {
      type: String,
      default: 'right',
      validator: alignment => ['bottom', 'right'].includes(alignment),
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters('users', ['getFavoritePathologies', 'getHasLoadedFavoritePathologies', 'isLoadingFavoritePathologies']),
    hasSelection () {
      if (this.multiple) {
        return !! this.value && this.value.length > 0;
      }
      return !! this.value?.label;
    },
    actions () {
      return [new ActionMenuItem('edit', 'Modifier la pathologie', () => this.$emit('input', null))];
    },
    tagsAlignmentClass () {
      if (this.multiple) {
        return `pathology-autocomplete-wrapper--tags-${this.tagsAlignment}`;
      }
      return '';
    },
    synapseFavorites() {
      return this.getFavoritePathologies.map(fav => fav.entity);
    },
  },
  watch: {
    value: {
      deep: true,
      async handler () {
        const validationResult = await validate(this.value?.label, this.rules);
        this.$refs.pathologyValidator?.applyResult(validationResult);
      },
    },
  },
  async mounted() {
    if (! this.isLoadingFavoritePathologies && ! this.getHasLoadedFavoritePathologies) {
      await this.fetchFavoritePathologies();
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions('users', ['fetchFavoritePathologies', 'insertFavoritePathology', 'deleteFavoritePathology']),
    handlePathologySelection (pathology) {
      this.$emit('input', this.multiple ? [...this.value, pathology] : pathology);
    },
    handlePathologyUnselection (pathologyIndex) {
      const localSelection = [...this.value];
      localSelection.splice(pathologyIndex, 1);
      this.$emit('input', localSelection);
    },
    async onFavoriteItemAdded(pathology) {
      this.insertFavoritePathology(pathology);
    },
    async onFavoriteItemDeleted(pathologyIdentifier) {
      const deletedPathology = this.getFavoritePathologies.find(fav => fav.entity.identifier.code === pathologyIdentifier.code);
      this.deleteFavoritePathology(deletedPathology);
    },
  },
};
</script>
<style lang="scss" scoped>
.pathology-autocomplete-wrapper {
  display: flex;
  gap: map-get($spacers, 2);

  &--tags-right {
    align-items: flex-end;
  }

  &--tags-bottom {
    flex-direction: column;
  }

  &__error-messages {
    color: var(--v-error-base);
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacers, 2) map-get($spacers, 2);
  }
}

::v-deep {
  .panel__header {
    margin-bottom: 0 !important;
  }
  .panel__title {
    white-space: normal !important;
  }
  .synapse-widget {
    // Nouveau Design System
    .autocomplete-search-input {
      fieldset {
        margin: 0 !important;

        label {
          font-family: 'Poppins' !important;
          border-left: none;
          border-right: none;
          border-top: none;
          border-radius: 0;
        }
      }
    }
  }
}
</style>
<style type="text/css" id="synapse-menu">
/* Menu Synapse */
.synapse-widget > div[class*="menu"] {
  border: none;
  box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
  border-radius: 0 0 3px 3px;
  margin-top: 0 !important;
}
.synapse-widget > div[class*="menu"] button:hover {
  background-color: var(--v-text-lighten5);
}
.synapse-widget > div[class*="menu"] button .autocomplete-suggestion-label {
  font-family: 'Poppins';
}
.synapse-widget > div[class*="menu"] button .autocomplete-suggestion-label > div:first-child {
  font-size: 13px;
}
.synapse-widget > div[class*="menu"] button .autocomplete-suggestion-label > div:last-child > div > span {
  font-size: 11px;
}
.synapse-widget > div[role="dialog"] > div.border-brand-primary {
  border-color: var(--v-border-base);
  margin-top: 0;
}
.synapse-widget > div[role="dialog"] div[class*="switch"] {
  background-color: var(--v-primary-base);
  padding: 2px;
}
.synapse-widget > div[class*="tooltipContent"] {
  font-family: 'Poppins';
  padding: 4px 8px;
}
</style>