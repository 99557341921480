<template>
  <component
    :is="actions && actions.length ? AppHoverableActionsWrapper : 'article'"
    tag="article"
    :inset-x="4"
    :actions="actions"
  >
    <div class="patient-sidebar-listing-item">
      <ul>
        <li>
          <h1 class="subtitle-3 flex-list-1 flex-wrap">
            <span 
              v-if="$scopedSlots['prepend-title']"
              v-test="'item-prepend-title'"
            >
              <slot name="prepend-title" />
            </span>
            <span v-test="'item-title'">{{ formattedTitle }}</span>
            <span
              v-if="$scopedSlots['append-title']"
              v-test="'item-append-title'"
              class="flex-list-1 flex-wrap align-center"
            >
              <slot name="append-title" />
            </span>
          </h1>
          <div
            v-if="displayDescription"
            v-test="'item-description'"
            class="patient-sidebar-listing-item__description"
            :class="{ 'secondary--text': !description }"
          >
            {{ description }}
          </div>
          <ul
            v-if="displaySummaries"
            class="patient-sidebar-listing-item__summaries"
          >
            <li
              v-for="(summary, index) in summaries"
              :key="index"
            >
              {{ summary }}
            </li>
          </ul>
        </li>
      </ul>
      <div
        v-if="$scopedSlots['prepend']"
        v-test="'item-prepend'"
        class="mt-1"
      >
        <slot name="prepend" />
      </div>
    </div>
  </component>
</template>

<script>
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import { titleCase } from '@/utils/functions/words';

import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue';



export default {
  name: 'PatientSidebarPanelListingItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    summaries: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: null,
      validator: (actions) => actions.every((action) => action instanceof ActionMenuItem),
    },
    skipTitleFormatting: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      AppHoverableActionsWrapper,
      isDescriptionOverflowing: false,
    };
  },
  computed: {
    formattedTitle () {
      return this.skipTitleFormatting ? this.title : titleCase(this.title);
    },
    displaySummaries () {
      return Boolean(this.summaries.length > 0);
    },
    displayDescription () {
      // With NextPosologyFormWidget, the comment is included in the summary so we don't need to display it on its own
      return this.description && ! this.summaries.find(summary => summary.toLowerCase().includes(this.description.toLowerCase()));
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-sidebar-listing-item {
  display: flex;
  align-items: flex-start;
  gap: map-get($spacers, 2);

  &__dot {
    display: flex;
    align-items: center;
    height: 20px;

    &:before {
      content: "";
      width: 5px;
      height: 5px;
      background-color: var(--v-secondary-lighten4);
      border-radius: 50%;
    }
  }

  h1 {
    font-weight: normal;
  }

  &__description,
  &__summaries {
    color: var(--v-secondary-base);
    font-size: 12px;
  }
}
</style>