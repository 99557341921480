import { ANTECEDENT_TYPES } from '@/modules/patient/constants';
import PatientAntecedent from '@/modules/patient/models/healthEntity/PatientAntecedent';
import store from '@/store/instance';

export default class PatientFamilyAntecedent extends PatientAntecedent {
  constructor (options = {}) {
    super(options);

    this.type = ANTECEDENT_TYPES.FAMILY.value;
    this.familyRelationship = options.familyRelationship || null;
  }

  getFamilyRelations() {
    return store.getters['app/getFamilyRelations'];
  }

  getLabel({ hideCode } = { hideCode: false }) {
    const codifiedLabel = super.getLabel({ hideCode });
    const familyRelation = this.getFamilyRelations().find(relation => relation['@id'] === this.familyRelationship);
    return `${codifiedLabel} (${familyRelation.label.toLowerCase()})`;
  }
}