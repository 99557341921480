import PatientHealthEntity from '@/modules/patient/models/healthEntity/PatientHealthEntity';

export default class PatientCodifiedHealthEntity extends PatientHealthEntity {
  constructor (options = {}) {
    super(options);

    this.theriaqueLink = options.theriaqueLink || null;
    this.code = options.code || null;
    this.codeTerminology = options.codeTerminology || null;
  }

  /**
   * Permet de récupérer le label de la pathologie (avec ou sans son code + terminologie)
   * @param {Object} [hideCode=false] Demande à renvoyer le code avec le label ou non
   * @returns {String} Le label de la pathologie
   */
  getLabel({ hideCode } = { hideCode: false }) {
    if (! hideCode && !! this.code) {
      return `${this.label} (${this.getCodification()})`;
    }
    return this.label;
  }

  /**
   * Permet de récupérer la codification de la pathologie (code + terminologie)
   * @returns {String} La codification
   */
  getCodification () {
    if (this.code) {
      return `${this.codeTerminology.toUpperCase()} - ${this.code}`;
    }
    return '';
  }

  isMatchingSynapseEntity (synapseEntity) {
    return this.code === synapseEntity.identifier.code && this.codeTerminology === synapseEntity.identifier.system;
  }
}