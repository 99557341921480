import Vue from 'vue';

import ConsultationDocument from '../models/ConsultationDocument';
import Prescription from '../models/Prescription';
import SephiraInvoice from '@/modules/accounting/models/SephiraInvoice';
import ConsultationCertificate from '@/modules/patient/components/consultation/certificates/models/ConsultationCertificate';
import ConsultationTelemedicineAct from '@/modules/patient/components/consultation/telemedicineActs/models/ConsultationTelemedicineAct';
import Consultation from '@/modules/patient/models/Consultation';
import ConsultationDischargeLetterDocument from '@/modules/patient/models/ConsultationDischargeLetterDocument';
import ConsultationFollowUpLetterDocument from '@/modules/patient/models/ConsultationFollowUpLetterDocument';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from '@/services/api';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';
import { parseNumber } from '@/utils/functions/number';

export const state = () => ({
  consultations: {},
  consultationPrescriptions: {},
  consultationDocuments: {},
  consultationInvoices: {},
  consultationFollowUpLetter: {},
  consultationDischargeLetter: {},
  consultationCertificates: {},
  consultationTelemedicineAct: {},
});

export const getters = {
  getPatientConsultations: state => (patientUUID) => {
    const consultations = Object
      .values(state.consultations[patientUUID] || {});

    consultations.sort((consultationA, consultationB) => new Date(consultationB.createdAt).getTime() - new Date(consultationA.createdAt).getTime());
    return consultations;
  },
  getPatientConsultationFromUUID: state => (patientUUID, consultationUUID) => state.consultations?.[patientUUID]?.[consultationUUID],
  getConsultationPrescriptions: state => (consultationUUID) => {
    if (! state.consultationPrescriptions[consultationUUID]) {
      return [];
    }

    return Object.values(state.consultationPrescriptions[consultationUUID])
      .slice()
      .sort((prescriptionsA, prescriptionsB) => new Date(prescriptionsB.createdAt).getTime() - new Date(prescriptionsA.createdAt).getTime());
  },
  getConsultationDocuments: state => (consultationUUID) => {
    if (! state.consultationDocuments[consultationUUID]) {
      return [];
    }

    return Object.values(state.consultationDocuments[consultationUUID])
      .slice()
      .sort((documentA, documentB) => new Date(documentB.createdAt).getTime() - new Date(documentA.createdAt).getTime());
  },
  getTotalConsultationDocumentsCount: (state, getters) => (consultationUuid) => [
    ...getters.getConsultationDocuments(consultationUuid),
    ...getters.getConsultationPrescriptions(consultationUuid),
    ...getters.getConsultationLetterDocuments(consultationUuid),
    ...getters.getConsultationCertificates(consultationUuid),
    ...[getters.getConsultationTelemedicineAct(consultationUuid)],
  ].filter(item => !! item).length,
  getConsultationLetterDocuments: (state, getters) => (consultationUuid) => [
    getters.getConsultationFollowUpLetter(consultationUuid),
    getters.getConsultationDischargeLetter(consultationUuid),
  ].filter(item => !! item),
  getConsultationFollowUpLetter: state => (consultationUuid) => {
    if (! state.consultationFollowUpLetter[consultationUuid]) {
      return null;
    }
    return state.consultationFollowUpLetter[consultationUuid];
  },
  getConsultationDischargeLetter: state => (consultationUuid) => {
    if (! state.consultationDischargeLetter[consultationUuid]) {
      return null;
    }
    return state.consultationDischargeLetter[consultationUuid];
  },
  getConsultationCertificates: state => (consultationUuid) => {
    if (! state.consultationCertificates[consultationUuid]) {
      return [];
    }
    return Object.values(state.consultationCertificates[consultationUuid]);
  },
  getConsultationTelemedicineAct: state => (consultationUuid) => {
    if (! state.consultationTelemedicineAct[consultationUuid]) {
      return null;
    }
    return state.consultationTelemedicineAct[consultationUuid];
  },
  getConsultationInvoice: state => (consultationUuid) => {
    if (! state.consultationInvoices?.[consultationUuid]) {
      return null;
    }

    return Object.values(state.consultationInvoices?.[consultationUuid])?.[0];
  },
};

export const mutations = {
  SET_CONSULTATION (state, consultation) {
    const patientUUID = getUUIDFromIRI(consultation.patient['@id']);
    const consultationUUID = getUUIDFromIRI(consultation['@id']);

    if (state.consultations[patientUUID] === undefined) {
      Vue.set(state.consultations, patientUUID, {});
    }

    Vue.set(state.consultations[patientUUID], consultationUUID, new Consultation(consultation));
  },
  SET_CONSULTATION_PRESCRIPTION (state, { consultation, prescription }) {
    const consultationUUID = getUUIDFromIRI(consultation['@id']);
    const prescriptionUUID = getUUIDFromIRI(prescription['@id']);

    if (state.consultationPrescriptions[consultationUUID] === undefined) {
      Vue.set(state.consultationPrescriptions, consultationUUID, {});
    }

    Vue.set(
      state.consultationPrescriptions[consultationUUID],
      prescriptionUUID,
      new Prescription(prescription),
    );
  },
  SET_PRESCRIPTION_DOCUMENT (state, consultationDocument) {
    const consultationUUID = getUUIDFromIRI(consultationDocument.consultation);
    const prescriptionUUID = getUUIDFromIRI(consultationDocument.prescription);
    state.consultationPrescriptions[consultationUUID][prescriptionUUID].document = consultationDocument;
  },
  SET_CONSULTATION_DOCUMENT (state, consultationDocument) {
    const consultationUuid = getUUIDFromIRI(consultationDocument.consultation);
    const documentUuid = getUUIDFromIRI(consultationDocument['@id']);

    if (state.consultationDocuments[consultationUuid] === undefined) {
      Vue.set(state.consultationDocuments, consultationUuid, {});
    }

    Vue.set(
      state.consultationDocuments[consultationUuid],
      documentUuid,
      new ConsultationDocument(consultationDocument),
    );
  },
  SET_CONSULTATION_FOLLOW_UP_LETTER (state, consultationFollowUpLetter) {
    const consultationUuid = getUUIDFromIRI(consultationFollowUpLetter.consultation);
    if (state.consultationFollowUpLetter[consultationUuid] === undefined) {
      Vue.set(state.consultationFollowUpLetter, consultationUuid, {});
    }
    Vue.set(state.consultationFollowUpLetter, consultationUuid, new ConsultationFollowUpLetterDocument(consultationFollowUpLetter));
  },
  SET_CONSULTATION_DISCHARGE_LETTER (state, consultationDischargeLetter) {
    const consultationUuid = getUUIDFromIRI(consultationDischargeLetter.consultation);
    if (state.consultationDischargeLetter[consultationUuid] === undefined) {
      Vue.set(state.consultationDischargeLetter, consultationUuid, {});
    }
    Vue.set(state.consultationDischargeLetter, consultationUuid, new ConsultationDischargeLetterDocument(consultationDischargeLetter));
  },
  SET_CONSULTATION_CERTIFICATE (state, consultationCertificate) {
    const consultationUuid = getUUIDFromIRI(consultationCertificate.consultation);
    const certificateUuid = getUUIDFromIRI(consultationCertificate['@id']);
    if (state.consultationCertificates[consultationUuid] === undefined) {
      Vue.set(state.consultationCertificates, consultationUuid, {});
    }
    Vue.set(state.consultationCertificates[consultationUuid], certificateUuid, new ConsultationCertificate(consultationCertificate));
  },
  SET_CONSULTATION_TELEMEDECINE_ACT (state, consultationTelemedicineAct) {
    const consultationUuid = getUUIDFromIRI(consultationTelemedicineAct.consultation);
    if (state.consultationTelemedicineAct[consultationUuid] === undefined) {
      Vue.set(state.consultationTelemedicineAct, consultationUuid, {});
    }
    Vue.set(state.consultationTelemedicineAct, consultationUuid, new ConsultationTelemedicineAct(consultationTelemedicineAct));
  },
  SET_CONSULTATION_INVOICE (state, consultationInvoice) {
    const consultationUuid = getUUIDFromIRI(consultationInvoice.consultation);
    const invoiceUuid = getUUIDFromIRI(consultationInvoice['@id']);

    if (state.consultationInvoices[consultationUuid] === undefined) {
      Vue.set(state.consultationInvoices, consultationUuid, {});
    }

    Vue.set(
      state.consultationInvoices[consultationUuid],
      invoiceUuid,
      new SephiraInvoice(consultationInvoice),
    );
  },
  DELETE_CONSULTATION_PRESCRIPTION (state, { consultation, prescription }) {
    const consultationUUID = getUUIDFromIRI(consultation['@id']);
    const prescriptionUUID = getUUIDFromIRI(prescription['@id']);
    Vue.delete(state.consultationPrescriptions[consultationUUID], prescriptionUUID);
  },
};

export const actions = {
  async deepCommitPatientConsultation ({ commit }, consultation) {

    // Commit de la consultation
    commit('SET_CONSULTATION', consultation);

    // Commit de chacune de ses prescriptions
    consultation.prescriptions.forEach(prescription => {
      commit('SET_CONSULTATION_PRESCRIPTION', {
        consultation,
        prescription,
      });
    });

    // Commit de chacun de ses documents
    consultation.documents.forEach(document => {
      commit('SET_CONSULTATION_DOCUMENT', document);
    });

    // Commit des lettres de suivi
    if (consultation.followUpLetterDocument) {
      commit('SET_CONSULTATION_FOLLOW_UP_LETTER', consultation.followUpLetterDocument);
    }

    // Commit de la lettre de liaison
    if (consultation.dischargeLetterDocument) {
      commit('SET_CONSULTATION_DISCHARGE_LETTER', consultation.dischargeLetterDocument);
    }

    // Commit des certificats
    consultation.certificateDocuments.forEach(certificate => {
      commit('SET_CONSULTATION_CERTIFICATE', certificate);
    });

    // Commit de l'acte de télémédecine
    if (consultation.telemedicineActDocument) {
      commit('SET_CONSULTATION_TELEMEDECINE_ACT', consultation.telemedicineActDocument);
    }

    // Commit de la facture associée et de ses paiements
    if (consultation.invoice) {
      commit('SET_CONSULTATION_INVOICE', consultation.invoice);
    }
  },
  async fetchPatientConsultations ({ dispatch }, patientId) {
    const { data } = await getFromAPI(`/api/patients/${patientId}/consultations`);
    data['hydra:member'].forEach(async consultation => dispatch('deepCommitPatientConsultation', consultation));
  },
  async fetchOnePatientConsultation ({ dispatch }, consultationUUID) {
    const { data } = await getFromAPI(`/api/consultations/${consultationUUID}`);
    await dispatch('deepCommitPatientConsultation', data);
  },
  async insertPatientConsultation ({ dispatch, getters }, { consultation, patient }) {
    consultation.patientMeasures = consultation.patientMeasures.filter(measure => measure.value);

    const { data } = await postToAPI('/api/consultations', {
      ...consultation,
      activityPlacePractitionerLink: consultation.activityPlacePractitionerLink?.['@id'] || null,
      patient: patient['@id'],
    });

    await dispatch('deepCommitPatientConsultation', data);

    return getters.getPatientConsultationFromUUID(
      getUUIDFromIRI(patient['@id']),
      getUUIDFromIRI(data['@id']),
    );
  },
  async updatePatientConsultation ({ dispatch }, { consultation, patient }) {
    consultation.patientMeasures = consultation.patientMeasures
      .filter(measure => measure.value)
      .map(measure => {
        measure.value = parseNumber(measure.value);
        return measure;
      });
    const { data } = await putToAPI(consultation['@id'], {
      data: {
        ...consultation,
        activityPlacePractitionerLink: consultation.activityPlacePractitionerLink?.['@id'] || null,
        patient: patient['@id'],
      },
    });
    await dispatch('deepCommitPatientConsultation', data);
    return data;
  },
  async insertConsultationPrescription ({ commit }, { prescription, consultation }) {
    const { data } = await postToAPI('/api/prescriptions', {
      ...prescription,
      consultation: consultation['@id'],
    });
    commit('SET_CONSULTATION_PRESCRIPTION', {
      consultation,
      prescription: data,
    });
    NovaTools.icanopee.efficience.sendAutoFromDocument(data.document);
  },
  async updateConsultationPrescription ({ commit }, { prescription, consultation }) {
    const { data } = await putToAPI(new Prescription(prescription).getIri(), {
      data: {
        ...prescription,
        consultation: consultation['@id'],
      },
    });
    commit('SET_CONSULTATION_PRESCRIPTION', {
      consultation,
      prescription: data,
    });
    NovaTools.icanopee.efficience.sendAutoFromDocument(data.document);
  },
  async insertConsultationDocument ({ commit }, consultationDocument) {
    const { data } = await postToAPI('/api/consultation_documents', consultationDocument);
    commit('SET_CONSULTATION_DOCUMENT', data);
    NovaTools.icanopee.efficience.sendAutoFromDocument(data);
  },
  async insertConsultationFollowUpLetter ({ commit }, consultationFollowUpLetter) {
    const { data } = await postToAPI('/api/consultation_follow_up_letter_documents', consultationFollowUpLetter);
    commit('SET_CONSULTATION_FOLLOW_UP_LETTER', data);
  },
  async insertConsultationDischargeLetter ({ commit }, consultationDischargeLetter) {
    const { data } = await postToAPI('/api/consultation_discharge_letter_documents', consultationDischargeLetter);
    commit('SET_CONSULTATION_DISCHARGE_LETTER', data);
    NovaTools.icanopee.efficience.sendAutoFromDocument(data);
    return new ConsultationDischargeLetterDocument(data);
  },
  async updateConsultationDischargeLetter ({ commit }, consultationDischargeLetter) {
    const { data } = await putToAPI(consultationDischargeLetter.getIri(), { data: consultationDischargeLetter });
    commit('SET_CONSULTATION_DISCHARGE_LETTER', data);
    NovaTools.icanopee.efficience.sendAutoFromDocument(data);
    return new ConsultationDischargeLetterDocument(data);
  },
  async insertConsultationCertificate ({ commit }, consultationCertificate) {
    const { data } = await postToAPI('/api/consultation_certificate_documents', consultationCertificate);
    commit('SET_CONSULTATION_CERTIFICATE', data);
    NovaTools.icanopee.efficience.sendAutoFromDocument(data);
    return new ConsultationCertificate(data);
  },
  async insertConsultationTelemedicineAct ({ commit }, consultationTelemedicineAct) {
    const { data } = await postToAPI('/api/consultation_telemedicine_act_documents', consultationTelemedicineAct);
    commit('SET_CONSULTATION_TELEMEDECINE_ACT', data);
    NovaTools.icanopee.efficience.sendAutoFromDocument(data);
    return new ConsultationTelemedicineAct(data);
  },
  async updateConsultationDocument ({ commit }, consultationDocument) {
    const { data } = await putToAPI(consultationDocument.getIri(), { data: consultationDocument });
    commit('SET_CONSULTATION_DOCUMENT', data);
    NovaTools.icanopee.efficience.sendAutoFromDocument(data);
  },
  /**
   * Permet de mettre à jour un document dans le store sans connaitre son type
   */
  updateDocument ({ commit }, document) {
    const documentsCommits = {
      ConsultationDischargeLetterDocument: 'SET_CONSULTATION_DISCHARGE_LETTER',
      ConsultationFollowUpLetterDocument: 'SET_CONSULTATION_FOLLOW_UP_LETTER',
      ConsultationCertificateDocument: 'SET_CONSULTATION_CERTIFICATE',
      ConsultationTelemedicineActDocument: 'SET_CONSULTATION_TELEMEDECINE_ACT',
      ConsultationDocument: 'SET_CONSULTATION_DOCUMENT',
      PrescriptionDocument: 'SET_PRESCRIPTION_DOCUMENT',
    };
    if (documentsCommits[document['@type']]) {
      commit(documentsCommits[document['@type']], document);
    }
  },
  async insertConsultationInvoice (store, { consultationUuid, carePathway }) {
    const { data } = await postToAPI('/api/consultation_invoices', {
      carePathway,
      consultationId: consultationUuid,
    });
    return data;
  },
  async deleteConsultationPrescription ({ commit }, { prescription, consultation }) {
    await deleteFromAPI(prescription['@id']);
    commit('DELETE_CONSULTATION_PRESCRIPTION', {
      prescription,
      consultation,
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};