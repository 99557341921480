import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';

/**
 * Permet de récupérer un objet PatientPathology à partir d'un objet Synapse PathologyV2
 * @param {Object} pathology
 * @returns {Object}
 */
export const asPatientPathology = pathology => new PatientPathology({
  label: pathology.identifier.display,
  code: pathology.identifier.code,
  codeTerminology: pathology.identifier.system,
});

/**
 * Permet de récupérer un objet Synapse PathologyV2 à partir d'un objet PatientPathology
 * @param {Object} pathology
 * @returns {Object}
 */
export const asSynapsePathologyObject = pathology => ({
  identifier: {
    code: pathology.code,
    display: pathology.label,
    system: pathology.codeTerminology.toLowerCase(),
  },
});

export const getSynapsePathologies = pathologies => pathologies.map(pathology => asSynapsePathologyObject(pathology));