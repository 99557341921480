<template>
  <div class="px-4 py-2">
    <n-alert
      v-if="uncodifiedPathologies.length"
      v-test="'uncodified-pathologies-alert'"
      type="warning"
      transparent
      title="Certaines pathologies seront ignorées pour la sécurisation LAP"
      message="Suite à la migration des pathologies sur le dictionnaire CIM-10,
      il est nécessaire de saisir à nouveau les pathologies pour qu'elles soient utilisées dans la sécurisation du LAP"
    />
    <n-list
      :loading="isLoading"
      :spacing="1"
      hoverable
      :inset-x="4"
      empty-text="Aucune pathologie"
      :items="pathologies"
    >
      <template #item="{ item }">
        <patient-sidebar-risk-factor
          v-test="'pathology'"
          :risk-factor="item"
          :actions="getPathologieActions(item)"
          favorite-enabled
          skip-title-formatting
          :is-favorite="isFavorite(item)"
          @add-favorite="onFavoriteItemAdded(item)"
          @delete-favorite="onFavoriteItemDeleted(item)"
        />
      </template>
      <template #skeleton>
        <app-skeleton-loader v-test="'pathology-listing-skeleton'" type="list-item-two-line" class="ma-0 pa-0" />
      </template>
    </n-list>
    <pathology-delete-confirm-modal
      v-model="deletingPathology"
      v-test="'delete-confirm-modal'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { asSynapsePathologyObject } from '@/components/lap/synapse-widgets/utils/synapsePathologyDataMapper';
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import PatientPathology from '@/modules/patient/models/healthEntity/PatientPathology';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';

import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import PathologyDeleteConfirmModal from '@/modules/patient/components/patientFile/pathologies/PathologyDeleteConfirmModal.vue';
import PathologyForm from '@/modules/patient/components/patientFile/pathologies/PathologyForm.vue';
import PatientSidebarRiskFactor from '@/modules/patient/components/PatientSidebarRiskFactor.vue';

export default {
  name: 'PathologyListing',
  components: {
    AppSkeletonLoader,
    PathologyDeleteConfirmModal,
    PatientSidebarRiskFactor,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      PathologyForm,
      deletingPathology: null,
      editingPathology: null,
    };
  },
  computed: {
    ...mapGetters('patient', ['getPatientPathologies', 'isLoadingPathologies', 'getHasLoadedPathologiesByPatientUuid']),
    ...mapGetters('users', ['getFavoritePathologies', 'getHasLoadedFavoritePathologies', 'isLoadingFavoritePathologies']),
    pathologies() {
      const pathologies = this.getPatientPathologies(this.patient.getUuid());
      return [
        ...pathologies.filter(item => item.important).sort((a, b) => a.label.localeCompare(b.label)),
        ...pathologies.filter(item => ! item.important).sort((a, b) => a.label.localeCompare(b.label)),
      ];
    },
    hasLoadedPathologies () {
      return this.getHasLoadedPathologiesByPatientUuid(this.patient.getUuid());
    },
    isLoading () {
      return this.isLoadingPathologies;
    },
    pathologyModalTitle () {
      return `${this.editingPathology?.['@id'] ? 'Modifier' : 'Ajouter'} une pathologie`;
    },
    uncodifiedPathologies () {
      return this.pathologies.filter(pathology => ! pathology.code);
    },
  },
  watch: {
    patient: {
      immediate: true,
      handler (patient) {
        if (patient['@id'] && ! this.hasLoadedPathologies && ! this.isLoading) {
          this.fetchPatientPathologies(this.patient.getUuid());
        }
      },
    },
  },
  async mounted() {
    if (!this.isLoadingFavoritePathologies && !this.getHasLoadedFavoritePathologies) {
      await this.fetchFavoritePathologies();
    }
  },
  methods: {
    ...mapActions('patient', ['fetchPatientPathologies']),
    ...mapActions('users', ['fetchFavoritePathologies', 'insertFavoritePathology', 'deleteFavoritePathology']),
    /**
     * Permet l'ouverture du formulaire de création par le composant parent
     */
    openForm () {
      this.openPathologyFormModal();
    },
    getPathologieActions (pathology) {
      return [
        new ActionMenuItem('edit', 'Éditer', () => this.openPathologyFormModal(pathology)),
        new ActionMenuItem('delete', 'Supprimer', () => {
          this.deletingPathology = pathology;
        }),
      ];
    },
    openPathologyFormModal (editingPathology = new PatientPathology()) {
      this.editingPathology = editingPathology;
      NovaTools.modal.appendModalForm({
        title: this.pathologyModalTitle,
        width: '500',
        form: PathologyForm,
        scrollable: false,
        patient: this.patient,
        value: this.editingPathology,
        mustTriggerFunction: true,
      }, () => {
        this.editingPathology = null;
      });
    },
    isFavorite(pathology) {
      return this.getFavoritePathologies.some(
        fav => pathology.isMatchingSynapseEntity(fav.entity)
      );
    },
    async onFavoriteItemAdded(pathology) {
      this.insertFavoritePathology(asSynapsePathologyObject(pathology));
    },
    async onFavoriteItemDeleted(pathology) {
      const deletedPathology = this.getFavoritePathologies.find(
        fav => pathology.isMatchingSynapseEntity(fav.entity)
      );
      this.deleteFavoritePathology(deletedPathology);
    },
  },
};
</script>