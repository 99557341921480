<template>
  <section
    class="consultation-files-list"
    :class="{ 'consultation-files-list--selectable': allowSelection }"
  >
    <p
      v-if="! items.length"
      v-test="'no-data-text'"
      class="consultation-files-list__no-data"
    >
      <slot name="no-data" />
    </p>
    <n-list
      v-else
      :divided="! dense"
      :items="items"
      :inset-x="dense ? 3 : 4"
      :spacing="3"
    >
      <template #item="{ item }">
        <consultation-file-list-item
          class="consultation-files-list__file"
          :class="{ 'consultation-files-list__file--selected': isSelected(item) }"
          :icon="getItemsIcon(item)"
          :dense="dense"
          :file="item"
          :hide-actions="hideActions"
          :hide-base-actions="hideBaseActions"
          :actions="getItemActions(item)"
          @click="handleClickItem(item)"
        >
          <template #title>
            <slot
              name="item-title"
              v-bind="{ item }"
            />
          </template>
          <template #description>
            <slot
              name="item-description"
              v-bind="{ item }"
            />
          </template>
        </consultation-file-list-item>
      </template>
    </n-list>
    <consult-prescription-modal
      v-if="ePrescriptionAmId"
      :am-id="ePrescriptionAmId"
      :is-open="isOpenEPrescription"
      @cancel="closeEPrescription"
    />
    <consultation-letters-document-modal-form
      v-if="!! editingLetter"
      v-test="'consultation-letters-modal-form'"
      :is-open.sync="isEditingLetter"
      :patient="patient"
      :consultation-letter-document.sync="editingLetter"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import ConsultationCertificate from './certificates/models/ConsultationCertificate';
import ConsultationTelemedicineAct from './telemedicineActs/models/ConsultationTelemedicineAct';
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import Correspondent from '@/models/user/Correspondent';
import DocumentDmpSendingAction from '@/modules/patient/classes/DocumentDmpSendingAction';
import DocumentMessagingSendingAction from '@/modules/patient/classes/DocumentMessagingSendingAction';
import PrescriptionTemplate
  from '@/modules/patient/components/consultation/prescription/templates/models/PrescriptionTemplate';
import { PATIENT_MESSAGING_DOCUMENT_SENDING_DENIAL_TEXT } from '@/modules/patient/constants';
import ConsultationBaseDocument from '@/modules/patient/models/ConsultationBaseDocuments';
import ConsultationDischargeLetterDocument from '@/modules/patient/models/ConsultationDischargeLetterDocument';
import ConsultationDocument from '@/modules/patient/models/ConsultationDocument';
import ConsultationLettersDocument from '@/modules/patient/models/ConsultationLettersDocument';
import IcanopeeDocument from '@/modules/patient/models/IcanopeeDocument';
import Prescription from '@/modules/patient/models/Prescription';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI } from '@/services/api';
import { isToday } from '@/utils/functions/dates';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';

import ConsultPrescriptionModal from '@/layout/default/components/header/searchMenu/searchPrescription/ConsultPrescriptionModal.vue';
import ConsultationFileListItem from '@/modules/patient/components/consultation/ConsultationFileListItem.vue';
import ConsultationLettersDocumentModalForm from '@/modules/patient/components/consultation/ConsultationLettersDocumentModalForm.vue';
import PrescriptionTemplateForm from '@/modules/patient/forms/PrescriptionTemplateForm.vue';

/**
 * Ce composant permet de wrapper un listing
 * de fichiers à récupérer depuis le dossier patient
 */
export default {
  name: 'ConsultationFileList',
  components: {
    ConsultPrescriptionModal,
    ConsultationFileListItem,
    ConsultationLettersDocumentModalForm,
  },
  props: {
    consultationUuid: {
      type: String,
      default: null,
    },
    patientIri: {
      type: String,
      required: true,
    },
    /**
     * Les éléments listés
     */
    items: {
      type: Array,
      required: true,
      validator: consultationFiles => consultationFiles.every(file => (file instanceof Prescription) || (file instanceof ConsultationBaseDocument)),
    },
    /**
     * Les icônes affiché pour chaque élément de la liste
     */
    itemsIcon: {
      type: [String, Function],
      default: null,
    },
    /**
     * Densifi l'affichage des éléments
     */
    dense: {
      type: Boolean,
      default: false,
    },
    /**
     * N'affiche pas les actions possible sur la prescription
     * Comme regenerer ou dupliquer
     */
    hideActions: {
      type: Boolean,
      default: false,
    },
    /**
     * N'affiche pas les actions de base
     * Comme télécharger
     */
    hideBaseActions: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de selectionner une ligne
     * La ligne selectionnée est renvoyé dans @selected
     */
    allowSelection: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selected: null,
      isOpenEPrescription: false,
      ePrescriptionAmId: null,
      editingLetter: null,
      isEditingLetter: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isCurrentUserPractitioner']),
    ...mapGetters('cardReaders', ['getHasCpxCard']),
    ...mapGetters('patient', ['getPatientByIRI', 'getPatientConsultationFromUUID']),
    patient () {
      return this.getPatientByIRI(this.patientIri);
    },
    consultation () {
      if (this.consultationUuid) {
        return this.getPatientConsultationFromUUID(getUUIDFromIRI(this.patientIri), this.consultationUuid);
      }
      return null;
    },
    consultationAuthor () {
      return this.consultation?.activityPlacePractitionerLink?.practitioner['@id'] ?? null;
    },
    isCurrentUserConsultationAuthor () {
      if (this.consultationAuthor) {
        return this.getCurrentUser().contact['@id'] === this.consultationAuthor;
      }
      return false;
    },
  },
  methods: {
    ...mapGetters('auth', ['getCurrentUser']),
    getItemsIcon (item) {
      if (typeof this.itemsIcon === 'function') {
        return this.itemsIcon(item);
      }
      return this.itemsIcon;
    },
    getItemActions (item) {
      if (this.hideActions) {
        return [];
      }
      const primaryActionsSection = [];
      const secondaryActionsSection = [];

      if (item instanceof PrescriptionTemplate) {

        primaryActionsSection.push(new ActionMenuItem('delete', 'Supprimer l\'ordonnance type', async () => {
          NovaTools.dialog.confirm(
            'Supprimer l\'ordonnance type ?',
            `Êtes-vous sûr(e) de vouloir supprimer l'ordonnance type "${item.name}" ?`,
            async () => {
              await PrescriptionTemplate.delete(new PrescriptionTemplate(item));
              this.$emit('refresh');
            },
          );
        },
        {
          disabled: item.createdBy !== this.getCurrentUser()['@id'],
          disabledTooltip: 'Vous ne pouvez supprimer que vos ordonnances type',
        }));

      } else {

        if (item instanceof ConsultationDocument) {
          primaryActionsSection.push(this.getDocumentEditionAction(item));
        }
        if (item instanceof Prescription) {
          primaryActionsSection.push(this.getPrescriptionDuplicationAction(item));
          primaryActionsSection.push(new ActionMenuItem('rotate', 'Régénérer l\'ordonnance', () => this.$emit('click:regenerate-pdf', item)));

          if (this.isCurrentUserPractitioner) {
            const { disabled, disabledTooltip } = this.getPrescriptionEditionOption(item);
            primaryActionsSection.push(new ActionMenuItem('edit', 'Modifier l\'ordonnance', () => this.$emit('click:edit', item), {
              disabled,
              disabledTooltip,
            }));
          }
          primaryActionsSection.push(this.getCreatePrescriptionTemplateAction(item));
        }
        if (item instanceof ConsultationDischargeLetterDocument
          || item instanceof ConsultationCertificate || item instanceof ConsultationTelemedicineAct) {
          primaryActionsSection.push(this.getIheArchiveDownloadAction(item));
        }

        if (this.isCurrentUserPractitioner) {
          secondaryActionsSection.push(this.getDocumentMessagingSendingAction(item, this.patient));
        }
        if (! (item instanceof ConsultationLettersDocument)) {
          secondaryActionsSection.push(this.getDocumentDmpSendingAction(item));
        }

        if (item instanceof ConsultationDischargeLetterDocument) {
          primaryActionsSection.push(new ActionMenuItem('edit', 'Modifier', () => this.updateDischargeLetter(item)));
        }

        if (item?.eprescription?.amId) {
          primaryActionsSection.push(this.getIheArchiveDownloadAction(item));
          secondaryActionsSection.push(this.getDisplayEPrescriptionAction(item));
        }

      }
      return [primaryActionsSection, secondaryActionsSection];
    },
    getIheArchiveDownloadAction (document) {
      const { disabled, disabledTooltip } = this.getNotQualifiedPatientOption(
        'Vous ne pouvez pas générer d\'IHE_XDM pour des patients au statut INS non qualifié',
      );
      return new ActionMenuItem('file-zip', 'Télécharger en IHE_XDM', () => this.$emit('click:download-ihe_xdm', document), {
        disabled,
        disabledTooltip,
      });
    },
    getDocumentEditionAction (document) {
      const { disabled, disabledTooltip } = this.getNotAuthorActionOption('Vous ne pouvez pas modifier les documents d\'un autre praticien');
      return new ActionMenuItem('edit', 'Modifier', () => this.$emit('click:edit', document), {
        disabled,
        disabledTooltip,
      });
    },
    getPrescriptionDuplicationAction (prescription) {
      return new ActionMenuItem('copy', 'Générer un duplicata', () => this.$emit('click:duplicate', prescription));
    },
    getCreatePrescriptionTemplateAction (prescription) {
      return new ActionMenuItem('prescription-template', 'Ajouter aux ordonnances types', async () => {
        NovaTools.modal.appendModalForm({
          prescription,
          title: 'Ajouter aux ordonnances types',
          form: PrescriptionTemplateForm,
          isOpened: true,
          scrollable: false,
        });
      });
    },
    getDocumentMessagingSendingAction (prescription, patient) {
      const { disabled: authorActionDisabled, disabledTooltip: authorActionDisabledTooltip } = this.getNotAuthorActionOption(
        'Vous ne pouvez pas envoyer les prescriptions d\'un autre praticien',
      );
      let disabled = authorActionDisabled;
      let disabledTooltip = authorActionDisabledTooltip;
      if (! disabled && ! patient.mssDocumentConsent) {
        disabled = true;
        disabledTooltip = PATIENT_MESSAGING_DOCUMENT_SENDING_DENIAL_TEXT;
      }
      prescription.patient = patient;
      return new DocumentMessagingSendingAction(prescription, {
        disabled,
        disabledTooltip,
      });
    },
    getDocumentDmpSendingAction (item) {
      if (item instanceof Prescription && item.document === null) {
        return new DocumentDmpSendingAction(this.patient, new IcanopeeDocument(), {
          disabled: true,
          disabledTooltip: 'Fichier non généré, veuillez le régénérer pour pouvoir envoyer au DMP',
        });
      }
      const { disabled, disabledTooltip } = this.getNotAuthorActionOption('Vous ne pouvez pas envoyer les prescriptions d\'un autre praticien');
      return new DocumentDmpSendingAction(this.patient, new IcanopeeDocument(this.patient.insIdentity.ins, {
        iri: item instanceof Prescription ? item.document['@id'] : item['@id'],
        title: item.name.replace('.pdf', ''),
        description: item.title,
        contentUrl: item.contentUrl,
        format: 'pdf',
        dmpUniqueId: item instanceof Prescription ? item.document.dmpUniqueId : item.dmpUniqueId,
        documentType: item instanceof Prescription ? item.document['@type'] : item['@type'],
        typeCode: item instanceof Prescription ? item.document.category : item.category,
      }), {
        disabled,
        disabledTooltip,
      });
    },
    getDisplayEPrescriptionAction (prescription) {
      const { disabled, disabledTooltip } = this.getNotAuthorActionOption('Vous ne pouvez pas voir les e-prescriptions d\'un autre praticien');
      return new ActionMenuItem(
        'attach',
        'Voir le détail de la ePrescription',
        () => this.openEPrescription(prescription?.eprescription?.amId),
        {
          disabled,
          disabledTooltip,
        },
      );
    },
    getPrescriptionEditionOption (prescription) {
      if (! isToday(new Date(prescription.createdAt))) {
        return {
          disabled: true,
          disabledTooltip: 'Vous ne pouvez modifier que les ordonnances du jour',
        };
      }
      if (prescription.isEprescription() && ! this.getHasCpxCard) {
        return {
          disabled: true,
          disabledTooltip: 'Vous ne pouvez pas modifier une ordonnance numérique en l\'absence d\'une carte CPS',
        };
      }
      if (! this.isCurrentUserConsultationAuthor) {
        return {
          disabled: true,
          disabledTooltip: 'Vous ne pouvez pas modifier les ordonnances d\'un autre praticien',
        };
      }
      return {
        disabled: false,
        disabledTooltip: null,
      };
    },
    getNotAuthorActionOption (reasonText) {
      let disabled = false;
      let disabledTooltip = null;
      if (! this.isCurrentUserConsultationAuthor) {
        disabled = true;
        disabledTooltip = reasonText;
      }
      return {
        disabled,
        disabledTooltip,
      };
    },
    getNotQualifiedPatientOption (reasonText) {
      let disabled = false;
      let disabledTooltip = null;
      if (! this.patient.insIdentity.isQualified()) {
        disabled = true;
        disabledTooltip = reasonText;
      }
      return {
        disabled,
        disabledTooltip,
      };
    },
    isSelected (item) {
      return this.selected === item;
    },
    handleClickItem (item) {
      if (! this.allowSelection) {
        return;
      }
      this.selectItem(item);
    },
    selectItem (item) {
      this.selected = item;
      this.$emit('selected', item);
    },
    async updateDischargeLetter (item) {
      const completeRecipients = await Promise.all(item.recipients.map((correspondentIri) => this.fetchCorrespondent(correspondentIri)));
      this.editingLetter = new ConsultationDischargeLetterDocument({
        ...item,
        recipients: completeRecipients,
      });
      this.isEditingLetter = true;
    },
    async fetchCorrespondent (correspondentIri) {
      const { data } = await getFromAPI(correspondentIri);
      return new Correspondent(data);
    },
    openEPrescription (amId) {
      this.ePrescriptionAmId = amId;
      this.isOpenEPrescription = true;
    },
    closeEPrescription () {
      this.ePrescriptionAmId = null;
      this.isOpenEPrescription = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$selected-color: #eee;

.consultation-files-list {
  &__no-data {
    font-size: 13px;
    color: var(--v-secondary-base);
  }

  &--selectable {
    .consultation-files-list__file {
      cursor: pointer;
      padding: 5px;
      border-radius: 5px;

      &:hover {
        background-color: $selected-color;
      }
    }

    ::v-deep {
      .consultation-files-list-item__headline__title {
        padding-left: map-get($spacers, 5);
      }
    }
  }

  &__file {
    &--selected {
      background-color: $selected-color;
    }
  }
}
</style>